import React from 'react';
import { Button, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { NavBarComponente } from '../../Components/NavBarComponente';
import "../../Estilo/Form.css";
import baixo from '../../Imagens/arrow-down-blue-16.png';
import voltar from '../../Imagens/arrow-left-16.png';
import voltarInicio from '../../Imagens/arrow-left-first-16.png';
import proximo from '../../Imagens/arrow-right-16.png';
import proximoUltimo from '../../Imagens/arrow-right-last-16.png';
import cima from '../../Imagens/arrow-up-blue-16.png';
import clearFilter from '../../Imagens/clear-filter.png';
import fechar from '../../Imagens/close-24.png';
import filtrar from '../../Imagens/filter-16.png';
import printer from '../../Imagens/printer.png';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { TypePagination } from '../TableGeneric/TableGenericViewController';
import { VisitRelatoryViewModel } from './VisitRelatoryViewModel';
import { GlobalViewModel } from '../Global/GlobalViewModel';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';
import moment from 'moment';

interface MyProps {
    navigation: any
    delegate: VisitRelatory
    viewModel: VisitRelatoryViewModel
}

export interface VisitRelatory {
    tapDimiss(navigation: any): void
}
export interface Filters {
    sgeppessoa: number,
    sgepmotivovisita: number,
    sgepgrupomotivovisita: number,
    motivo: string,
    data_de: string,
    data_ate: string,
    pagina: number;
}

export class VisitRelatoryViewController extends React.Component<MyProps> {

    private globalViewModel = new GlobalViewModel()
    private formEvent: FormEvent
    state = {
        isLoading: true,
        json: {},
        data: Array<any>(),
        total: 0,
        personList: Array<any>(),
        visitReasonList: Array<any>(),
        groupVisitReasonList: Array<any>(),
        clearValue: false,
        showFilter: false,
        filters: ({
            sgeppessoa: '',
            sgepmotivovisita: '',
            sgepgrupomotivovisita: '',
            data_de: '',
            data_ate: '',
            pagina: 1
        }),
        countPage: 1
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadData()
        this.setState({
            isLoading: false
        })
    }

    render() {
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Relatório de Visitas</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                        onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                        src={fechar} />
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row className="header">
                                <Col xs="12" className="infoFilter">
                                    <h3 onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                                        <Image
                                            className="iconFilter"
                                            src={this.state.showFilter ? cima : baixo} />
                                        EXIBIR FILTROS
                                    </h3>
                                </Col>
                                {this.state.showFilter &&
                                    <Col xs="12" className="containerFilter">
                                        <Form>
                                            <Row>
                                                <Col lg="3" xs="12" className="bottom10">
                                                    <InputModalSelectComponent
                                                        id="sgeppessoa"
                                                        keyValue="nome"
                                                        label="Responsável"
                                                        required={false}
                                                        placeholder="Procure"
                                                        showAddButton={false}
                                                        navigation={this.props.navigation}
                                                        titleModal="Pessoas"
                                                        table="geppessoas"
                                                        valueDefault={this.state.filters.sgeppessoa}
                                                        getValue={(id: number, value: string) => this.verifyFilters('sgeppessoa', String(id))}
                                                        clearValue={this.state.clearValue}
                                                        setClearValue={() => this.setState({ clearValue: false })}
                                                    />
                                                </Col>
                                                <Col lg="3" xs="12" className="bottom10">
                                                    <Form.Label>Motivo Visita</Form.Label>
                                                    <Select
                                                        id="sgepmotivovisita"
                                                        value={this.state.filters.sgepmotivovisita ? this.formEvent.onValueSelect('select', 'camada', this.state.visitReasonList) : null}
                                                        onChange={(e: any) => this.verifyFilters('sgepmotivovisita', e ? e.value : null)}
                                                        closeMenuOnSelect={true}
                                                        placeholder={"Selecione"}
                                                        options={this.state.visitReasonList}
                                                        isClearable
                                                    />
                                                </Col>
                                                <Col lg="2" xs="12" className="bottom10">
                                                    <Form.Label>Grupo de Motivo de Visita</Form.Label>
                                                    <Select
                                                        id="sgepgrupomotivovisita"
                                                        value={this.state.filters.sgepgrupomotivovisita ? this.formEvent.onValueSelect('select', 'camada', this.state.groupVisitReasonList) : null}
                                                        onChange={(e: any) => this.verifyFilters('sgepgrupomotivovisita', e ? e.value : null)}
                                                        closeMenuOnSelect={true}
                                                        placeholder={"Selecione"}
                                                        options={this.state.groupVisitReasonList}
                                                        isClearable
                                                    />
                                                </Col>
                                                <Col lg="2" xs="12" className="bottom10">
                                                    <Form.Label>De</Form.Label>
                                                    <Form.Control
                                                        id="data_de"
                                                        type="date"
                                                        value={this.state.filters.data_de}
                                                        onChange={(e: any) => this.verifyFilters('data_de', e.target.value)}
                                                        placeholder="Digite..." />
                                                </Col>
                                                <Col lg="2" xs="12" className="bottom10">
                                                    <Form.Label>Até</Form.Label>
                                                    <Form.Control
                                                        id="data_ate"
                                                        type="date"
                                                        value={this.state.filters.data_ate}
                                                        onChange={(e: any) => this.verifyFilters('data_ate', e.target.value)}
                                                        placeholder="Digite..." />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs='12'>
                                                    <Button
                                                        onClick={() => this.screenUpdate()}
                                                        variant="info"
                                                        type="button"
                                                        className="btnFilter right10">
                                                        <Image
                                                            className="iconBtnFilter"
                                                            src={filtrar} />
                                                        Filtrar
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            this.clearFilters()
                                                            this.setState({
                                                                clearValue: true
                                                            })
                                                        }}
                                                        variant="info"
                                                        type="button"
                                                        className="btnFilter">
                                                        <Image
                                                            className="iconBtnFilter"
                                                            src={clearFilter} />
                                                        Limpar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                }

                                <Col xs="12" className='mt-2'>
                                    <Button variant="danger" type="button" className="buttonGeneric colorRed"
                                        onClick={() => this.printRelatory(this.state.filters)}>
                                        <Image
                                            className="iconButtonGeneric"
                                            src={printer} />
                                        PDF
                                    </Button>
                                </Col>
                            </Row>
                            <>
                                <Table responsive hover style={{ textAlign: 'left', fontSize: 14, backgroundColor: "#ffffff" }}>
                                    <thead style={{ backgroundColor: "#eeeeee" }}>
                                        <tr>
                                            <th>Sequencial</th>
                                            <th>Profissional</th>
                                            <th>Motivo</th>
                                            <th>Data</th>
                                            <th>Observação</th>
                                            <th>Ponto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data && this.state.data.map((item, key) => (
                                            <tr key={key}>
                                                <td>{item.sgepvisita}</td>
                                                <td>{item.profissional}</td>
                                                <td>{item.motivovisita}</td>
                                                <td>{moment(item.hora, 'HH:mm').format('HH:mm')} {moment(item.data).format('DD/MM/YYYY')}</td>
                                                <td>{item.obs}</td>
                                                <td>{item.sshape}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div style={{ borderWidth: 1, borderStyle: "solid", borderColor: "#cccccc", backgroundColor: "#eeeeee" }}>
                                    <Container fluid={true}>
                                        <Row>
                                            <Col xs="12">
                                                <div style={{ height: 30 }}>
                                                    <div style={{ float: "right" }}>
                                                        <label style={{ marginLeft: 10, fontSize: 12, fontWeight: "bold" }}>Número de Registros: {this.state.total}</label>
                                                    </div>
                                                    {this.state.data.length ? (
                                                        <div style={{ float: "left" }}>
                                                            {this.state.filters.pagina != 1 && (
                                                                <>
                                                                    <Image
                                                                        style={{ marginLeft: 0, cursor: "pointer" }}
                                                                        src={voltarInicio}
                                                                        onClick={() => this.pagination('first')}
                                                                        width={16}
                                                                        className="center"
                                                                        fluid />
                                                                    <Image
                                                                        style={{ marginLeft: 10, cursor: "pointer" }}
                                                                        src={voltar}
                                                                        onClick={() => this.pagination('back')}
                                                                        width={16}
                                                                        className="center"
                                                                        fluid />
                                                                </>
                                                            )}
                                                            <label className="tablePage">{this.state.filters.pagina}/{this.state.countPage}</label>
                                                            {this.state.filters.pagina !== this.state.countPage && (
                                                                <>
                                                                    <Image
                                                                        style={{ marginLeft: 10, cursor: "pointer" }}
                                                                        src={proximo}
                                                                        onClick={() => this.pagination('next')}
                                                                        width={16}
                                                                        className="center"
                                                                        fluid />
                                                                    <Image
                                                                        style={{ marginLeft: 10, cursor: "pointer" }}
                                                                        src={proximoUltimo}
                                                                        onClick={() => this.pagination('last')}
                                                                        width={16}
                                                                        className="center"
                                                                        fluid />
                                                                </>
                                                            )}
                                                        </div>
                                                    ) : <label className="tablePage">0/{this.state.countPage}</label>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </>

                        </Form>
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({ isLoading: this.state.isLoading })} />
            </div>
        )
    }

    private printRelatory(filters: any) {
        this.setState({
            isLoading: true
        })

        this.props.viewModel.fetchVisitsRelatory(filters).then((data) => {
            this.setState({
                isLoading: false
            })
        })

    }

    private loadData() {
        this.screenUpdate()
        this.lists()

    }

    private screenUpdate() {
        this.setState({ isLoading: true })
        this.props.viewModel.fetchVisits(this.state.filters).then((response: any) => {
            console.log(response)
            if (response.length) {
                this.setState({
                    isLoading: false,
                    data: response,
                    countPage: Math.ceil(response[0].total / 10),
                    total: Number(response[0].total)
                })
            } else {
                this.setState({
                    isLoading: false,
                    data: response,
                    countPage: 0,
                    total: 0
                })
            }
        })
    }

    private lists() {
        this.globalViewModel.fetchDataToSelectInput('geppessoas', 'sgeppessoa').then((response: any) => {
            if (response.length) {
                this.setState({
                    personList: response
                })
            }
        })
        this.globalViewModel.fetchDataToSelectInput('gepmotivovisita', 'sgepmotivovisita', 'gepmotivovisita').then((response: any) => {
            if (response.length) {
                this.setState({
                    visitReasonList: response
                })
            }
        })
        this.globalViewModel.fetchDataToSelectInput('gepgrupomotivovisita', 'sgepgrupomotivovisita').then((response: any) => {
            if (response.length) {
                this.setState({
                    groupVisitReasonList: response,
                })
            }
        })

    }

    private verifyFilters(key: keyof Filters, newValue: string) {
        let newFilters = this.state.filters as any;
        newFilters[key] = newValue;
        if (key !== 'pagina') newFilters['pagina'] = 1;
        this.setState({
            filters: newFilters
        });
    }

    private async pagination(type: TypePagination) {
        var page
        switch (type) {
            case 'first':
                page = 1
                break;
            case 'back':
                page = this.state.filters.pagina - 1
                break;
            case 'next':
                page = this.state.filters.pagina + 1
                break;
            case 'last':
                page = this.state.countPage
                break;
        }
        await this.setState({
            filters: {
                ...this.state.filters,
                pagina: page
            }
        })
        this.screenUpdate()
    }

    private async clearFilters() {
        await this.setState({
            filters: {
                sgeppessoa: '',
                sgepmotivovisita: '',
                sgepgrupomotivovisita: '',
                motivo: '',
                data: '',
                pagina: 1
            }
        })
        this.screenUpdate()
    }
}