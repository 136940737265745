import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import "../../Estilo/ImportExportModal.css";

interface MeuModalProps {
    navigation: any;
    item: any;
    component: any;
}

export default function ImportModal({ component, item, navigation }: MeuModalProps) {
    const options = ([
        { value: 31978, label: "SIRGAS2000 / UTM18S" },
        { value: 31979, label: "SIRGAS2000 / UTM19S" },
        { value: 31980, label: "SIRGAS2000 / UTM20S" },
        { value: 31981, label: "SIRGAS2000 / UTM21S" },
        { value: 31982, label: "SIRGAS2000 / UTM22S" },
        { value: 31983, label: "SIRGAS2000 / UTM23S" },
        { value: 31984, label: "SIRGAS2000 / UTM24S" },
        { value: 31985, label: "SIRGAS2000 / UTM25S" },
        { value: 32718, label: "WGS84 / UTM18S" },
        { value: 32719, label: "WGS84 / UTM19S" },
        { value: 32720, label: "WGS84 / UTM20S" },
        { value: 32721, label: "WGS84 / UTM21S" },
        { value: 32722, label: "WGS84 / UTM22S" },
        { value: 32723, label: "WGS84 / UTM23S" },
        { value: 32724, label: "WGS84 / UTM24S" },
        { value: 32725, label: "WGS84 / UTM25S" },
        { value: 4674, label: "SIRGAS2000" },
        { value: 4326, label: "WGS84" },
    ])

    const [selectedEPSG, setSelectedEPSG] = useState<{ value: number; label: string } | null>(null);

    function handleClose() {
        setSelectedEPSG(null);
        component.setState({
            showModal: false
        })
    };

    function handleSelectChange(selectedOption: any) {
        setSelectedEPSG(selectedOption);
    };

    useEffect(() => {
        if (selectedEPSG) {
            if (item.uploadFile) {
                let input = document.createElement('input');
                input.type = 'file';
                input.multiple = item.multipleFile!;
                input.click();
                input.onchange = (e: any) => {
                    component.setState({ showLoading: true });

                    let formData = new FormData();
                    let files = e.target.files;
                    for (let i = 0; i < files.length; i++) {
                        formData.append(`images[${i}]`, files[i]);
                    }

                    let response = {
                        files,
                        json: { id: component.state.selectionIndex },
                        viewModel: component.props.viewModel
                    };

                    item.action(component.props.navigation, response, component, selectedEPSG.value);
                };
            } else {
                let response = {
                    id: component.state.selectionIndex,
                    viewModel: component.props.viewModel
                };

                item.action(component.props.navigation, response, component, selectedEPSG.value);
            }

            // Adiciona um delay antes de fechar
            const timeout = setTimeout(() => {
                handleClose();
            }, 1000); // Fecha após 2 segundos

            return () => clearTimeout(timeout); // Limpa o timeout se `selectedEPSG` mudar antes do tempo acabar
        }
    }, [selectedEPSG]);


    return (
        <Modal
            show={component.state.showModal}
            onHide={handleClose}
            centered
            contentClassName="content-import-export"
            backdropClassName="backdrop"
        >
            <Modal.Body>
                <h1>Atenção!</h1>
                {!selectedEPSG ? (
                    <Form>
                        <Form.Group>
                            <p>Sistema de Referência / Coordenadas:</p>
                            <Select
                                options={options}
                                value={selectedEPSG}
                                onChange={handleSelectChange}
                                placeholder="Selecione..."
                                isSearchable
                                maxMenuHeight={200} // Limita a altura da lista
                            />
                        </Form.Group>
                    </Form>
                ) : (
                    <div>
                        <label>Sistema de Referência / Coordenadas:</label>
                        <h6 className="d-flex justify-content-center bold border my-2">
                            {selectedEPSG.label}
                        </h6>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}
