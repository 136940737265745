import { AdminProcessRelatoryInteractor } from "../../Coordinator/AdminProcessRelatory/AdminProcessRelatoryInteractor";

export class AdminProcessRelatoryViewModel {

    private interactor: AdminProcessRelatoryInteractor
    constructor() {
        this.interactor = new AdminProcessRelatoryInteractor()
    }

    public fetchAdminProcess(filters: any): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchAdminProcess(filters).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }

    public fetchAdminProcessRelatory(filters: any): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchAdminProcessRelatory(filters).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }
}