import { VisitRelatoryInteractor } from "../../Coordinator/VisitRelatory/VisitRelatoryInteractor";

export class VisitRelatoryViewModel {

    private interactor: VisitRelatoryInteractor
    constructor() {
        this.interactor = new VisitRelatoryInteractor()
    }

    public fetchVisits(filters: any): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchVisits(filters).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }

    public fetchVisitsRelatory(filters: any): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchVisitsRelatory(filters).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }
}