import React from 'react';
import ProtectedRoute from '../../Components/ProtectedRoute';
import { VisitRelatory, VisitRelatoryViewController } from "../../Screen/VisitRelatory/VisitRelatoryViewController";
import { VisitRelatoryViewModel } from '../../Screen/VisitRelatory/VisitRelatoryViewModel';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";

export class VisitRelatoryCoordinator implements IndexCoordinatorDelegate, VisitRelatory {

    getRoute(): JSX.Element[] {
        return [
            this.routeVisitRelatory(),
        ]
    }

    private routeVisitRelatory(): JSX.Element {
        var viewModel = new VisitRelatoryViewModel()
        return (<ProtectedRoute requiredPermissions={['relatorios.visita.*']} path="/:base/relatorio/visita" exact={true} component={(props: any) => {
            return (<VisitRelatoryViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
            />)
        }} />)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
}