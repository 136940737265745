import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import relatory from '../Imagens/relatory.png';
import { LoaderComponente } from './LoaderComponente';
import { MapaComponente } from './MapaComponente';

interface MyProps {
    setActivedNeighborRelatory: (isActived: Boolean) => void
    mapComponent: MapaComponente
}

export class RelatorioVizinhosComponente extends React.Component<MyProps> {

    state = {
        showLoading: false,
    }
    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <div id="neighbor-relatory" className="neighbor-relatory" onClick={() => { this.NeighborRelatory(); }}>
                            <Image
                                title={"Relatório de Lindeiros"}
                                style={{ marginLeft: 3, cursor: "pointer", marginTop: 4, padding: 2 }}
                                src={relatory}
                                width={24} />
                        </div>
                    </Col>
                </Row>
                <LoaderComponente show={this.state.showLoading} hide={() => this.setState({ showLoading: this.state.showLoading })} />
            </div>
        )
    }
    private NeighborRelatory() {
        if (this.props.mapComponent?.props.activedNeighborRelatory) {
            if (!this.props.mapComponent?.props.selectedShapes || this.props.mapComponent?.props.selectedShapes?.length < 1) {
                confirmAlert({
                    title: 'Atenção!',
                    message: 'Selecione ao menos um Ponto para gerar o relatório.',
                    buttons: [
                        { label: 'Ok', onClick: () => { } },
                        {
                            label: 'Cancelar', onClick: () => {
                                this.props.mapComponent?.props.setActivedNeighborRelatory(!this.props.mapComponent?.props.activedNeighborRelatory)
                            }
                        }
                    ]

                })
            } else {
                confirmAlert({
                    title: 'Atenção!',
                    message: 'Deseja gerar relatório dos Pontos selecionados?',
                    buttons: [{
                        label: 'Sim',
                        onClick: () => {
                            if (this.props.mapComponent?.props.selectedShapes) {
                                this.props.mapComponent?.props.exportPDF(this.props.mapComponent?.props.selectedShapes)
                                this.props.mapComponent?.props.setActivedNeighborRelatory(!this.props.mapComponent?.props.activedNeighborRelatory)
                            }
                        }
                    },
                    {
                        label: 'Cancelar',
                        onClick: () => {
                            this.props.mapComponent?.restoreSelectedShapes()
                            this.props.mapComponent?.props.setActivedNeighborRelatory(!this.props.mapComponent?.props.activedNeighborRelatory)
                            this.props.mapComponent?.props.setSelectedShapes()
                        }
                    }]
                })
            }
        } else {
            this.props.mapComponent?.props.setSelectedShapes([])
            confirmAlert({
                title: 'Gerar Relatório',
                message: 'Selecione os Pontos desejados para gerar o relatório, e em seguida clique novamente no botão.',
                buttons: [{ label: 'Ok', onClick: () => { } }]
            })
            this.props.mapComponent?.props.setActivedNeighborRelatory(!this.props.mapComponent?.props.activedNeighborRelatory)
        }
    }
}