import { openFilter } from '../../Components/FiltrosMapa/FiltrosEmMapaComponente';
import { Request } from '../../Servidor/Request';

export class MapFilterInteractor {

    public getRelatoryByFilter(tipoPonto: string, filterLayers: Array<number>, openedFilter: openFilter, filters: any) {
        const filterData = {
            filters: filters,
            tipoPonto: tipoPonto,
            camadas: filterLayers
        }
        let endpoint = "";
        switch (openedFilter) {
            case 'fotoCapa':
                endpoint = "relatorio/filtrar-mapa/foto-capa";
                break;
            case 'dadosEditar':
                endpoint = "relatorio/filtrar-mapa/dados-editar";
                break;
            case 'endereco':
                endpoint = "relatorio/filtrar-mapa/endereco";
                break;
            case 'cadastro':
                endpoint = "relatorio/filtrar-mapa/cadastro";
                break;
            case 'edificacao':
                endpoint = "relatorio/filtrar-mapa/edificacao";
                break;
            case 'cadastro-tecnico':
                endpoint = "relatorio/filtrar-mapa/cadastro-tecnico";
                break;
            case 'pessoa':
                endpoint = "relatorio/filtrar-mapa/pessoa";
                break;
            case 'visita':
                endpoint = "relatorio/filtrar-mapa/visita";
                break;
            case 'processo-administrativo':
                endpoint = "relatorio/filtrar-mapa/processo-administrativo";
                break;
            default:
                return Promise.reject({ Mensagem: "Filtro inválido" });
        }
        return new Promise((res, rej) => {
            return new Request().requestPost(endpoint, { ...filterData }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }
                res(rs)
            }, e => {
                rej(e);
            });
        })
    }

}