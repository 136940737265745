import { openFilter } from "../../Components/FiltrosMapa/FiltrosEmMapaComponente";
import { MapFilterInteractor } from "../../Coordinator/MapFilter/MapFilterInteractor";


export class MapFilterViewModel {
    private interactor: MapFilterInteractor;

    constructor() {
        this.interactor = new MapFilterInteractor();
    }

    public async getMapPoints(tipoPonto: string, filterLayers: Array<number>, openedFilter: openFilter, filters: any) {
        return await new Promise((res, rej) => {
            this.interactor.getRelatoryByFilter(tipoPonto, filterLayers, openedFilter, filters).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }
}