import { ImobiliaryRegisterRelatoryInteractor } from "../../Coordinator/ImobiliaryRegisterRelatory/ImobiliaryRegisterRelatoryInteractor";

export enum ConsolidatedReportType {
    district, neighborhood
}

export class ImobiliaryRegisterRelatoryViewModel {

    private interactor: ImobiliaryRegisterRelatoryInteractor
    constructor() {
        this.interactor = new ImobiliaryRegisterRelatoryInteractor()
    }

    public fetchImobiliaryRegisters(filters: any): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchImobiliaryRegisters(filters).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }

    public fetchImobiliaryRegistersRelatory(filters: any, tpprop: string): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchImobiliaryRegistersRelatory(filters, tpprop).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }
    public fetchPointNumberRelatory(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchPointNumberRelatory().then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }
}