import React from 'react';
import { MapInitialViewModel } from "./MapInitialViewModel"
import { MapaComponente } from '../../Components/MapaComponente';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { ShapeComponente, Action } from '../../Components/ShapeComponente';
import { confirmAlert } from 'react-confirm-alert';
import { ModalAttributesShapeComponente } from '../../Components/ModalAttributesShapeComponente';
import { GeoJSON, Icon } from 'leaflet';
import { ToastContainer, toast } from 'react-toastify';
import { AddShapeModalComponente } from '../../Components/AddShapeModalComponente';
import moment from 'moment';
import * as L from 'leaflet';
import * as turf from 'turf'

interface MyProps {
    navigation: any
    delegate: MapInitialViewControllerDelegate,
    viewModel: MapInitialViewModel
}

export interface MapInitialViewControllerDelegate {
}

type Crud = "add" | "edit" | "delete"
export interface Shape {
    type: Crud
    geojson: any
    sshape: number
    scamada?: number
    layer?: any
}

export class MapInitialViewController extends React.Component<MyProps> {

    private layerClick: any;
    private layerCreated: any;
    private layerAfterEdit: any;
    private styleLayerClick: any;
    private startEditShape: boolean;
    private arrayDraw: Array<Shape>;
    private map: any;
    private mapComponent?: MapaComponente
    private drawShape: Boolean
    private addShapeModalComponent?: AddShapeModalComponente

    state = {
        showInfoShape: false,
        typeShape: "",
        atributesShape: {},
        startEdit: false,
        showModalAttributes: false,
        showAddShape: false,
        activedNeighborRelatory: false,
        activedShapeUnification: false,
        activedRotateMode: false,
        selectedShapes: [] as Array<any>,
        isSeparating: false


    }

    constructor(props: any) {
        super(props)
        this.startEditShape = false
        this.arrayDraw = new Array<Shape>()
        this.drawShape = false
    }


    componentDidMount() {
        this.eventKeyboard()
        if (localStorage.getItem('layers') != null && this.mapComponent != null) {
            let obj = JSON.parse(localStorage.getItem('layers')!)
            this.mapComponent.setLayersAtivos(obj['layers'])
        }
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.activedNeighborRelatory == false && this.state.activedNeighborRelatory == true) {
            this.setState({
                showInfoShape: false
            })
        }
        if (prevState.startEdit == false && this.state.startEdit == true) {
            this.mapComponent?.restoreSelectedShapes();
        }
    }

    private eventKeyboard() {
        document.addEventListener("keyup", (ev) => {
            if (this.map.pm.globalRemovalModeEnabled() && ev.keyCode == 13) {
                this.map.pm.disableGlobalDragMode()
            }

            if (this.startEditShape && ev.keyCode == 13) {
                this.setState({
                    showModalAttributes: true
                })
            }

            if (this.layerCreated && ev.keyCode == 13) {
                this.setState({
                    showModalAttributes: true
                })
            }

            if (this.state.startEdit && ev.keyCode == 46) {
                if (this.startEditShape) {
                    this.layerClick.disableEdit()
                    this.startEditShape = false
                }

                this.deleteShape()
            }
        });
    }

    private deleteShape() {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse shape?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    var geojson = this.layerClick.feature
                    if (geojson['properties']['sshape']) {
                        this.arrayDraw.push({
                            type: "delete",
                            geojson: {},
                            sshape: geojson['properties']['sshape']
                        })
                    } else {
                        for (var i = 0; i < this.arrayDraw.length; i++) {
                            var item = this.arrayDraw[i]
                            if (item.geojson == geojson) {
                                this.arrayDraw.splice(i, 1)
                                break
                            }
                        }
                    }


                    this.layerClick.remove()
                    this.layerClick = null
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    render() {
        var attributes = this.state.atributesShape as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                {this.state.showInfoShape && !this.state.activedNeighborRelatory &&
                    < ShapeComponente
                        sshape={attributes && attributes['sshape'] ? attributes['sshape'] : 0}
                        typeShape={this.state.typeShape}
                        attributes={attributes}
                        startEdit={this.state.startEdit}
                        activedRotateMode={this.state.activedRotateMode}
                        clickAction={(action, sshape) => this.clickActionInfoShape(action, [sshape])}
                        clickLink={(link) => this.openLink(link)}
                        close={() => this.setState({ showInfoShape: false })}
                    />}
                {this.state.showModalAttributes &&
                    <ModalAttributesShapeComponente
                        show={this.state.showModalAttributes}
                        layer={this.layerCreated ? this.layerCreated : this.layerClick}
                        clickHide={(isSave: boolean, obj: any) => this.clickActionsModal(isSave, obj)} />
                }
                <MapaComponente
                    ref={(e: any) => this.mapComponent = e}
                    height={window.innerHeight - 80}
                    isDraw={true}
                    isCamada={true}
                    isSearch={true}
                    isFilter={true}
                    isNeighborRelatory={true}
                    isShapeUnification={true}
                    activedRotateMode={this.state.activedRotateMode}
                    activedShapeUnification={this.state.activedShapeUnification}
                    setActivedShapeUnification={(isActived: Boolean) => this.setState({ activedShapeUnification: isActived })}
                    activedNeighborRelatory={this.state.activedNeighborRelatory}
                    setActivedNeighborRelatory={(isActived: Boolean) => this.setState({ activedNeighborRelatory: isActived })}
                    exportPDF={(sshape) => this.exportPDF(sshape, true)}
                    selectedShapes={this.state.selectedShapes}
                    setSelectedShapes={(shape: any) => this.setState({ selectedShapes: shape })}
                    getClickShape={(e: any) => this.clickShape(e)}
                    getMap={(map: any) => this.setMap(map)}
                    saveEdit={() => this.saveEdit()}
                    startEdit={() => this.startEdit()}
                    cancelEdit={() => this.cancelEdit()}
                    stopDraw={() => this.stopDraw()}
                    startDrawShape={() => this.drawShape = true}
                    clickRight={(e: any) => this.clickRight(e)} />
                <AddShapeModalComponente
                    ref={(ref: any) => this.addShapeModalComponent = ref}
                    show={this.state.showAddShape}
                    clickHide={(isShow: boolean, json: any) => this.saveCancelDrawVector(isShow, json)} />
                <ToastContainer />
            </div>
        )
    }

    private saveCancelDrawVector(isSave: boolean, json: any) {
        this.drawShape = false
        this.map.fire('custom:disable-draw')
        this.map.pm.disableDraw()

        if (this.layerCreated) {
            this.layerCreated.remove()
        }

        if (!isSave) {
            this.setState({
                showAddShape: false
            })

            return
        }

        let layer = this.mapComponent?.getLayerJSONSingle(json) as any
        if (this.layerClick) {
            json['properties'] = this.layerClick.feature.properties
            layer.feature = json
        }

        this.saveByVertex(layer)
        this.setState({
            showAddShape: false
        })
    }

    private saveByVertex(layer: any) {
        if (this.layerCreated) {
            this.layerCreated = layer
            this.setState({
                showModalAttributes: true
            })

            return
        }

        this.layerClick.remove()
        this.map.addLayer(layer)

        var obj = layer.toGeoJSON()['features'][0]['properties']
        if (obj['sshapetemp']) {
            for (var i = 0; i < this.arrayDraw.length; i++) {
                var item = this.arrayDraw[i].geojson as any
                if (item['properties']) {
                    if (item['properties']['sshapetemp'] == obj['sshapetemp']) {
                        this.arrayDraw.splice(i, 1)
                        break
                    }
                }
            }
        }

        this.arrayDraw.push({
            type: "edit",
            geojson: layer.toGeoJSON()['features'][0],
            sshape: obj['sshape'] ? obj['sshape'] : 0,
            scamada: obj.scamada
        })

        this.startEditShape = false
    }

    private openEditDrawVector() {
        var coordinates = new Array<any>()
        var geom = this.layerClick.feature.geometry as any
        var typeGeom = ""

        if (geom['type'] == 'Polygon') {
            typeGeom = 'P'
            var geomCoordinates = geom['coordinates'][0] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        } else if (geom['type'] == 'MultiPolygon') {
            typeGeom = 'P'
            var geomCoordinates = geom['coordinates'][0][0] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        } else if (geom['type'] == 'MultiLineString') {
            typeGeom = 'L'
            var geomCoordinates = geom['coordinates'][0] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        } else if (geom['type'] == 'LineString') {
            typeGeom = 'L'
            var geomCoordinates = geom['coordinates'] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        }

        if (this.addShapeModalComponent) {
            this.addShapeModalComponent.refreshCoordinator(coordinates, typeGeom)
        }

        this.setState({
            showAddShape: true
        })
    }

    private stopDraw() {
        if (this.startEditShape) {
            this.setState({ showModalAttributes: true })
        }
    }

    private clickRight(e: any) {
        if (this.state.startEdit) {
            return
        }

        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja criar um ponto aqui?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    var latlng = e.latlng
                    this.props.navigation.history.push(`ponto/latitude/${latlng.lat}/longitude/${latlng.lng}`)
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private clickActionsModal(isSave: boolean, obj: any) {
        this.map.fire('custom:disable-draw')

        if (this.layerClick) {
            this.map.pm.disableDraw()
        }

        if (!isSave && this.layerClick) {
            this.layerClick.remove()
            var layerCancel = this.mapComponent?.getLayerJSONSingle(this.layerAfterEdit) as any
            this.map.addLayer(layerCancel)

            this.setState({
                showInfoShape: false
            })
        }

        if (this.layerCreated) {
            this.map.pm.disableDraw()
        }

        if (!isSave && this.layerCreated) {
            this.layerCreated.remove()
        }

        if (isSave && this.layerClick) {
            this.layerClick.feature.properties = obj
            this.layerClick.redraw()

            if (obj['sshapetemp']) {
                for (var i = 0; i < this.arrayDraw.length; i++) {
                    var item = this.arrayDraw[i].geojson as any
                    if (item['properties']) {
                        if (item['properties']['sshapetemp'] == obj['sshapetemp']) {
                            this.arrayDraw.splice(i, 1)
                            break
                        }
                    }
                }
            }

            let layer = this.layerClick.toGeoJSON()
            layer['properties'] = obj

            this.layerClick.remove()
            var layerUpdate = this.mapComponent?.getLayerJSONSingle(layer) as any
            this.map.addLayer(layerUpdate)

            this.arrayDraw.push({
                type: "edit",
                geojson: layer,
                sshape: obj['sshape'] ? obj['sshape'] : 0,
                scamada: obj.scamada
            })
        }

        if (isSave && this.layerCreated) {
            this.layerCreated.remove()
            var geojson = this.layerCreated.toGeoJSON()
            if (geojson['properties'] == undefined) {
                geojson = geojson['features'][0]
            }

            geojson['properties'] = obj
            geojson['properties']['sshapetemp'] = `id-${new Date().getTime()}`

            var layer = new GeoJSON(geojson, {
                onEachFeature: (feature, layer) => {
                    layer.on({
                        click: this.clickShape.bind(this)
                    })
                }
            });

            this.map.addLayer(layer)
            this.arrayDraw.push({
                type: "add",
                geojson: geojson,
                sshape: 0,
                scamada: obj.scamada,
                layer: layer
            })

            this.layerCreated = null
        }

        this.startEditShape = false
        this.setState({
            showModalAttributes: false
        })
    }

    private clickShape(e: any) {
        const allowMultiSelect = this.state.activedNeighborRelatory || this.state.activedShapeUnification;


        if (this.startEditShape || this.state.isSeparating) {
            return;
        }
        const layer = e.target as any;
        const shapeId = layer.feature.properties.sshape;
        // Forma normal de selecionar apenas 1 shape por vez
        if (!allowMultiSelect) {
            this.setState({ selectedShapes: [layer.feature] });
            if (this.layerClick && this.styleLayerClick) {
                this.layerClick.disableEdit();
                if (this.styleLayerClick instanceof Icon) {
                    this.layerClick.setIcon(this.styleLayerClick);
                } else {
                    this.layerClick.setStyle(this.styleLayerClick);
                }
            }

            this.layerClick = layer;
            this.layerAfterEdit = layer.toGeoJSON();

            if (layer.options.style) {
                this.styleLayerClick = layer.options.style;
                const estilo = {
                    fillColor: layer.options.style.fillColorClick,
                    color: layer.options.style.colorClick,
                };
                layer.setStyle(estilo);
            }

            if (layer.options.icon) {
                this.styleLayerClick = layer.options.icon;
                const geometryStyle = layer.feature.style;
                if (geometryStyle["iconeClick"]) {
                    const icon = new Icon({
                        iconUrl: geometryStyle["iconeClick"],
                    });
                    layer.setIcon(icon);
                }
            }

            this.storageInfoMap();
            this.setState({
                showInfoShape: true,
                typeShape: layer.feature.geometry.type,
                atributesShape: layer.feature.properties,
            });
        } else {
            // Forma para selecionar múltiplos shapes e armazenar o objeto completo
            if (layer.options.style) {
                this.styleLayerClick = layer.options.style;
                const estilo = {
                    fillColor: layer.options.style.fillColorClick,
                    color: layer.options.style.colorClick,
                };
                layer.setStyle(estilo);
            }

            const geometryStyle = layer.feature.style;

            // Adicionar o objeto inteiro ao estado
            if (!this.state.selectedShapes.find((shape: any) => shape.feature.properties.sshape === shapeId)) {
                if (geometryStyle && geometryStyle["iconeClick"]) {
                    const icon = new Icon({
                        iconUrl: geometryStyle["iconeClick"],
                    });
                    layer.setIcon(icon);
                }
                this.setState({
                    selectedShapes: [...this.state.selectedShapes, layer],
                });
            } else {
                // Remover o shape do estado
                const filteredShapes = this.state.selectedShapes.filter(
                    (shape: any) => shape.feature.properties.sshape !== shapeId
                );

                const shapeLayer: any = this.mapComponent?.getLayerById(shapeId);
                this.setState({
                    selectedShapes: filteredShapes,
                });

                const feature = shapeLayer.feature;
                const originalStyle = feature.style;
                if (originalStyle) {
                    if (shapeLayer.feature.geometry.type === "Point") {
                        const icon = new Icon({
                            iconUrl: feature.properties.sgeppontos
                                ? originalStyle["iconeDados"]
                                : originalStyle["icone"],
                        });
                        shapeLayer.setIcon(icon);
                    } else if (
                        shapeLayer.feature.geometry.type === "MultiLineString" ||
                        shapeLayer.feature.geometry.type === "LineString"
                    ) {
                        shapeLayer.setStyle({
                            color: originalStyle.color,
                        });
                    } else {
                        shapeLayer.setStyle({
                            fillColor: originalStyle.fillColor,
                        });
                    }
                }
            }
        }
    }



    private storageInfoMap() {
        var latlng = this.map._lastCenter
        let zoom = this.map._zoom

        if (latlng && zoom) {
            localStorage.setItem('latitude', latlng.lat)
            localStorage.setItem('longitude', latlng.lng)
            localStorage.setItem('zoom', zoom)
        }

        if (this.mapComponent) {
            var layers = this.mapComponent?.getLayersAtivos()
            var keys = Object.keys(layers)
            localStorage.setItem('layers', JSON.stringify({ layers: keys }))
        }


    }

    private setMap(map: any) {
        if (map) {
            map.on('pm:create', (e: any) => {

                if (!this.state.isSeparating) {
                    this.layerCreated = e.layer
                    if (this.drawShape) {
                        var coordinates = [{
                            lat: this.layerCreated._latlng['lat'],
                            lng: this.layerCreated._latlng['lng']
                        }]

                        if (this.addShapeModalComponent) {
                            this.addShapeModalComponent.refreshCoordinator(coordinates, '')
                        }

                        this.setState({
                            showAddShape: true
                        })
                        return
                    }

                    this.setState({
                        showModalAttributes: true
                    })
                } else {

                    //Original = poligono antes do corte
                    //CuttedFeature = area que eu desenhei pro corte
                    //Difference = poligono original, sem a area cortada (Original - cutted)
                    const original = { ...this.layerClick.feature };

                    const newPolygon = e.layer._latlngs[0].map(({ lat, lng }: any) => [lng, lat]);
                    newPolygon.push(newPolygon[0])
                    const cuttedFeature = turf.polygon([
                        newPolygon
                    ]) as any

                    // const cuttedFeature = e.layer.feature;
                    const cuttedFeatureBuffered = turf.buffer(cuttedFeature.geometry, 0.00001);
                    const diference = turf.difference(original.geometry, cuttedFeatureBuffered) as any
                    cuttedFeature.properties = { ...original.properties }
                    cuttedFeature.style = original.style
                    diference.properties = { ...original.properties }
                    diference.style = original.style

                    L.geoJSON(diference, {
                        style: original.style,
                        onEachFeature: (feature, layer) => {
                            if (feature.properties && this.mapComponent?.shapesAtivos) {
                                this.mapComponent.shapesAtivos[feature['properties']['sshape']] = layer
                            }
                            if (feature.properties && this.mapComponent?.shapesAtivos) {
                                this.mapComponent.shapesAtivos = layer
                            }
                            layer.on({
                                click: this.clickShape.bind(this)
                            })
                        }
                    }).addTo(this.map);
                    this.map.removeLayer(this.layerClick)
                    this.map.removeLayer(e.layer)

                    this.props.viewModel.fetchCutFeature({ updatedFeature: diference, cuttedFeature }).then((response) => {
                        if (cuttedFeature.properties) {
                            cuttedFeature.properties.sshape = response.Resultado[0]
                            L.geoJSON(cuttedFeature, {
                                style: original.style,
                                onEachFeature: (feature, layer) => {

                                    if (feature.properties && this.mapComponent?.shapesAtivos) {
                                        this.mapComponent.shapesAtivos[feature['properties']['sshape']] = layer
                                    }
                                    if (feature.properties && this.mapComponent?.shapesAtivos) {
                                        this.mapComponent.shapesAtivos = layer
                                    }
                                    layer.on({
                                        click: this.clickShape.bind(this)
                                    })
                                }
                            }).addTo(this.map);

                            window.location.reload()
                        }
                    })

                    this.map.dragging.enable();
                    this.map.doubleClickZoom.enable();
                    this.mapComponent?.setState({ activeSeparatedFeature: false })
                    this.setState({
                        isSeparating: false
                    })
                    map.off('pm:create')

                }
            })
            map.on('pm:cut', (e: any) => {
                this.layerClick = e.layer
                this.layerClick['feature']['properties'] = e.originalLayer['feature']['properties']
                this.setState({
                    showModalAttributes: true
                })
            })



            this.map = map
        }
    }

    private startEdit() {
        this.setState({
            startEdit: !this.state.startEdit
        })
    }

    private clickActionInfoShape(action: Action, sshape: number[]) {
        var database = localStorage.getItem('database')
        let attributes = this.state.atributesShape as any
        switch (action) {
            case "addPoint":
                if (attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/ponto/cad/${attributes['sshape']}`)
                }
                break;
            case "editPoint":
                if (attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/ponto/cad/${attributes['sshape']}`)
                }
                break;
            case "deletePoint":
                if (attributes['sshape']) {
                    this.deletePoint(attributes['sshape'])
                }
                break;
            case "editShape":
                this.startEditShape = true;
                this.layerClick.pm.enable();
                break;
            case "editShapeCoordinator":
                this.openEditDrawVector()
                break;
            case "cutShape":
                this.startEditShape = true;
                this.map.pm.enableDraw('Cut', {
                    tooltips: false
                });
                break;
            case "moveShape":
                this.startEditShape = !this.startEditShape
                this.map.pm.toggleGlobalDragMode();
                break;
            case "attributesShape":
                this.setState({
                    showModalAttributes: true
                })
                break;
            case "deleteShape":
                this.deleteShape()
                break;
            case "visitPoint":
                if (attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/visita`)
                } else if (attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/shape/${attributes['sshape']}/visita`)
                }
                break;
            case "property":
                if (attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/propriedade/tipo/${attributes['tipo']}`)
                }
                break;
            case "address":
                if (attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/endereco`)
                }
                break;
            case "adminProcess":
                if (attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/shape/${attributes['sshape']}/processo-administrativo`)
                }
                break;
            case "imagem":
                if (attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/arquivos`)
                }
                break;
            case "export":
                this.exportPDF(this.layerClick)
                break;
            case "duplicate":
                this.duplicateShape()
                break;
            case "rotate":
                this.rotateShape()
                break;
            case "saveRotate":
                this.saveRotatedShape()
                break;
            case "separateShape":
                this.separateShape()
                break;
            case "unificateShape":
                this.unificateShape()
                break;
            case "streetView":
                this.openStreetView()
                break;
            default:
                break;
        }
    }

    private openLink(link: string) {
        let database = localStorage.getItem('database')
        this.props.navigation.history.push(`/${database}/${link}`)
    }

    private deletePoint(sshape: number) {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.props.viewModel.fetchRemovePoint({ sshape: sshape }).then(() => {
                        this.layerClick.remove()
                        this.layerClick = null

                        toast.success('Registro deletado com sucesso!', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                        this.setState({
                            showInfoShape: false
                        })
                    }, e => {
                        toast.error(JSON.stringify(e), {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private cancelEdit() {
        if (this.layerClick) {
            this.layerClick.disableEdit()
        }

        if (this.layerCreated) {
            this.layerCreated.disableEdit()
        }


        this.startEditShape = false
        window.location.reload()
    }

    private saveEdit() {
        this.props.viewModel.fetchSaveDraw(this.arrayDraw).then(() => {
            window.location.reload()
        }, e => {
            this.arrayDraw.forEach((item) => {
                if (item.layer) {
                    item.layer!.remove()
                }
            })

            this.arrayDraw = []
            this.mapComponent?.reloadShapesCamadas(false)

            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private exportPDF(sshape: any[], isNeighborRelatory?: boolean) {
        const shapesArray = Array.isArray(sshape) ? sshape : [sshape];
        const arraySshape = shapesArray.map((shape) => {
            return shape.feature.properties.sshape
        })
        this.mapComponent?.setLoading(true);
        let footerPDF = "";
        this.props.viewModel.fetchMapPDF(arraySshape).then(async (rs) => {
            let firstShape = rs[0].filter((x: any) => x['key'] == 'shape')[0]?.['values'] as Array<any>;

            let geom = firstShape[0].geom;
            let geomType = geom.type
            let latlng;

            latlng = this.mapComponent?.getGeometryCenter(geom.coordinates, geomType)
            this.map.panTo(latlng)
            for (const [index, point] of rs.entries()) {
                let property = point.filter((x: any) => x['key'] == 'propriedade')[0]?.['values'] as Array<any>;
                let building = point.filter((x: any) => x['key'] == 'edificacoes')[0]?.['values'] as Array<any>;
                let person = point.filter((x: any) => x['key'] == 'pessoas')[0]?.['values'] as Array<any>;
                let address = point.filter((x: any) => x['key'] == 'endereco')[0]?.['values'] as Array<any>;
                let shape = point.filter((x: any) => x['key'] == 'shape')[0]?.['values'] as Array<any>;
                let attributes = point.filter((x: any) => x['key'] == 'atributos')[0]?.['values'] as Array<any>;
                let visit = point.filter((x: any) => x['key'] == 'visitas')[0]?.['values'] as Array<any>;
                let processAdm = point.filter((x: any) => x['key'] == 'processoAdm')[0]?.['values'] as Array<any>;

                let shapeImageHTML = '';
                if (shape && index >= 1) {
                    shapeImageHTML = await this.getShapeImageHTML(shape[0]);
                }
                const centerCords = this.mapComponent?.getGeometryCenter(shape[0].geom.coordinates, shape[0].geom.type)
                let currentFooterHTML = '';
                if (property && property.length > 0) {
                    currentFooterHTML = this.getFooterPDF(property, building, person, address, attributes, visit, processAdm, centerCords);
                } else {
                    currentFooterHTML = this.getAtributesShapePDF(true, attributes, visit, processAdm, centerCords);
                }

                const isNeighbor = index >= 1 && !!isNeighborRelatory
                footerPDF += `
                    <div  ${isNeighbor ? 'style="page-break-inside: avoid;"' : ''}>
                    ${isNeighbor ? `<div class="header-mapa-pdf container-fluid">
                        <div class="row header-mapa-pdf-bottom">
                            <div class="col-2">
                                <image class="img-fluid" src="https://storage.googleapis.com/gep_images_production/geo/logo-login.png" />
                            </div>
                            <div class="col-10">
                                <h1 class="header-mapa-pdf-title">Lindeiro ${index}</h1>
                            </div>
                        </div>
                    </div>` : ""} 
                        ${shapeImageHTML}
                        ${currentFooterHTML}
                        ${isNeighbor ? `<div style="page-break-after: always;"></div>` : (index + 1 == rs.length) ? '' : '<div style="page-break-before: always;"></div>'}
                    </div
                    `
                this.mapComponent?.setShapeLegend(shape);
                this.mapComponent?.restoreSelectedShapes();

            };
            setTimeout(() => {
                this.mapComponent?.exportPDF(
                    `${isNeighborRelatory ? 'Relatório de Lindeiros' : 'Relatório em Mapa'}`,
                    footerPDF,
                    !!isNeighborRelatory
                );
            }, 1000);

        }, e => {
            this.mapComponent?.setLoading(false);
            toast.error("Erro interno!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private async getShapeImageHTML(shapeData: any): Promise<string> {
        if (!shapeData?.geom?.coordinates || shapeData?.geom?.coordinates.length === 0) {
            return '';
        }
        const { geom } = shapeData;
        let center: { lat: number, lng: number };
        let coords: string | null = null;
        let path
        const shapeStyle = this.mapComponent?.layerClick.feature.style;
        let style: any;

        if (geom.type === 'Point' || geom.type === 'MultiPoint') {
            const coordinates = geom.coordinates;
            center = { lat: coordinates[1], lng: coordinates[0] };
        } else if (geom.type === 'LineString') {
            const coordinates = geom.coordinates;
            center = this.mapComponent?.getGeometryCenter(geom.coordinates, geom.type)!;
            coords = coordinates.map((coord: any) => `${coord[1]},${coord[0]}`).join('|');
            style = {
                color: shapeStyle?.colorClick || '#FF0000',
                weight: shapeStyle?.weight || 5
            };
            path = `&path=color:0x${(style.color || 'FF0000').replace('#', '')}|weight:${style.weight}|${coords}`
        } else if (geom.type === 'MultiLineString') {
            const coordinates = geom.coordinates.flat();
            center = this.mapComponent?.getGeometryCenter(geom.coordinates, geom.type)!;
            coords = coordinates.map((coord: any) => `${coord[1]},${coord[0]}`).join('|');
            style = {
                color: shapeStyle?.colorClick || '#FF0000',
                weight: shapeStyle?.weight || 5
            };
            path = `&path=color:0x${(style.color || 'FF0000').replace('#', '')}|weight:${style.weight}|${coords}`
        } else if (geom.type === 'Polygon') {
            const coordinates = geom.coordinates[0];
            center = this.mapComponent?.getGeometryCenter(geom.coordinates, geom.type)!;
            coords = coordinates.map((coord: any) => `${coord[1]},${coord[0]}`).join('|');
            style = {
                fillColor: shapeStyle?.fillColorClick || '#FF0000',
                weight: shapeStyle?.weight || 5
            };
            path = `&path=fillcolor:0x${(style.fillColor || '00FF0080').replace('#', '')}|weight:${style.weight}|${coords}`
        } else if (geom.type === 'MultiPolygon') {
            const coordinates = geom.coordinates.flat(2);
            center = this.mapComponent?.getGeometryCenter(geom.coordinates, geom.type)!;
            coords = coordinates.map((coord: any) => `${coord[1]},${coord[0]}`).join('|');
            style = {
                fillColor: shapeStyle?.fillColorClick || '#FF0000',
                weight: shapeStyle?.weight || 5
            };
            path = `&path=fillcolor:0x${(style.fillColor || '00FF0080').replace('#', '')}|weight:${style.weight}|${coords}`
        } else {
            return '';
        }

        const apiKey = 'AIzaSyCXDmzBDlnV7R1Yg1V9q0Hd9Y6aEqvpHEs';
        const baseUrl = `https://maps.googleapis.com/maps/api/staticmap`;
        const centerParam = `center=${center.lat},${center.lng}`;
        const zoomParam = `zoom=${this.map._zoom}`;
        const sizeParam = `size=600x500`;
        const maptypeParam = `maptype=hybrid`;
        const pathParam = path ? path : '';
        const markersParam = geom.type === 'Point' || geom.type === 'MultiPoint'
            ? `&markers=icon:${encodeURIComponent('https://storage.googleapis.com/gep_images_production/geo/marker_vermelho.png')}|${center.lat},${center.lng}`
            : '';

        const imageUrl = `${baseUrl}?${centerParam}&${zoomParam}&${sizeParam}&${maptypeParam}${pathParam}${markersParam}&key=${apiKey}`;


        // Garantir que a imagem foi carregada
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                resolve(`
                    <div style="display: flex; justify-content: center;">
                        <img src="${imageUrl}" alt="Mapa do Shape">
                    </div>
                `);
            };
            img.onerror = () => {
                resolve(''); // Retorna vazio caso a imagem não carregue
            };
        });
    }


    private getFooterPDF(property: Array<any>, building: Array<any>, person: Array<any>, address: Array<any>, attributes: Array<any>, visit: Array<any>, processAdm: Array<any>, shape: any): string {
        var htmlContentProperty = ``
        var propertyItens = property[0]['itens'] as Array<any>
        if (propertyItens.length > 0) {
            htmlContentProperty = this.getTableItemValue(propertyItens)
        }

        var htmlContentBuilding = ``
        if (building.length > 0) {
            htmlContentBuilding = `<h3 style="margin-bottom: 20px; margin-top: 50px;">Dados das Edificações</h3>`
            building.forEach((item) => {
                let buildingItens = item['itens'] as Array<any>
                var htmlContentBuildingItens = ``
                if (buildingItens.length > 0) {
                    htmlContentBuildingItens = this.getTableItemValue(buildingItens)
                }

                htmlContentBuilding += `
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col-2">Data Cadastro</th>
                                    <th scope="col-6">Inscrição Imobiliária</th>
                                    <th scope="col-2">Total</th>
                                    <th scope="col-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>${this.getFormatData(item['anoinicial'])} - ${this.getFormatData(item['anofinal'])}</td>
                                    <td>${item['inscricao'] == null ? 'Sem informação' : item['inscricao']}</td>
                                    <td>${property[0]['total']}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                ${htmlContentBuildingItens}
                ${this.getTablePerson(person, item['id'], 'edificacao', 'P')}
                ${this.getTablePerson(person, item['id'], 'edificacao', 'R')}
                ${this.getVisitShapePDF('Visitas', 'edificacao', visit)}
                ${this.getVisitShapePDF('Processos Administrativo', 'edificacao', processAdm)}
                `
            })
        }

        let footer = `
        <div class="container-fluid" style="margin-top: 50px;">
            ${this.getAtributesShapePDF(true, attributes, visit, processAdm, shape)}
            ${this.getTableAddress(address)}
            ${this.getVisitShapePDF('Visitas', 'ponto', visit)}
            ${this.getVisitShapePDF('Processos Administrativo', 'ponto', processAdm)}
            <h3 style="margin-bottom: 20px; margin-top: 50px;">Dados da Propriedade</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-2">Data Cadastro</th>
                                <th scope="col-6">Inscrição Imobiliária</th>
                                <th scope="col-2">Total</th>
                                <th scope="col-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${this.getFormatData(property[0]['anoinicial'])} - ${this.getFormatData(property[0]['anofinal'])}</td>
                                <td>${property[0]['inscricao'] == null ? 'Sem informação' : property[0]['inscricao']}</td>
                                <td>${property[0]['total']}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            ${htmlContentProperty}
            ${this.getTablePerson(person, property[0]['id'], 'propriedade', 'P')}
            ${this.getTablePerson(person, property[0]['id'], 'propriedade', 'R')}
            ${this.getVisitShapePDF('Visitas', 'propriedade', visit)}
            ${this.getVisitShapePDF('Processos Administrativo', 'propriedade', processAdm)}
            ${htmlContentBuilding}
        </div>        
        `

        return footer
    }
    private getAtributesShapePDF(hasProperty: boolean, list: Array<any>, visit: Array<any>, processAdm: Array<any>, shape: any): string {
        let layerName = list.filter((x: any) => x['key'] == 'Nome da Camada Geográfica')
        let layerType = list.filter((x: any) => x['key'] == 'Tipo da Camada Geográfica')
        let layerFilter = list.filter((x: any) => x['key'] != 'Nome da Camada Geográfica' && x['key'] != 'Tipo da Camada Geográfica')
        var outputItens = ``
        layerFilter.forEach((item) => {
            outputItens += `<tr>
                                <td>${item['key']}</td>
                                <td>${item['value']}</td>
                            </tr>`
        })
        let coordinates = `<tr>
                                <td>Coordenadas</td>
                                <td>${shape.lat + ' | ' + shape.lng}</td>
                            </tr>`

        let output = `
            <h3 style="margin-bottom: 20px; margin-top: 50px;">Dados Geográfico</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${layerName[0]['key']}</td>
                                <td>${layerName[0]['value']}</td>
                            </tr>
                             <tr>
                                <td>${layerType[0]['key']}</td>
                                <td>${layerType[0]['value']}</td>
                            </tr>
                            ${coordinates}
                            ${outputItens}
                        </tbody>
                    </table>
                </div>
            </div>
        `

        if (!hasProperty) {
            return `
                <div class="container-fluid" style="margin-top: 50px;">
                    ${output}
                    ${this.getVisitShapePDF('Visitas', 'ponto', visit)}
                    ${this.getVisitShapePDF('Processos Administrativo', 'ponto', processAdm)}
                </div>
            `
        }

        return output
    }

    private getFormatData(data: string): string {
        return moment(data).format('DD/MM/YYYY')
    }

    private getTableItemValue(list: Array<any>): string {
        var itensProperty = ``
        list.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td scope="row">${index + 1}</td>
                                    <td>${item['item']}</td>
                                    <td>${item['valor']}</td>
                                </tr>`
        })

        return `
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-2">#</th>
                                <th scope="col-4">Item</th>
                                <th scope="col-6">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    private getTablePerson(list: Array<any>, id: number, table: string, grpType: string): string {
        let listFilter = list.filter((x: any) => x['id'] == id && x['tabela'] == table && x['tipo'] == grpType)
        if (listFilter.length == 0) {
            return ''
        }

        var itensProperty = ``
        listFilter.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td scope="row">${index + 1}</td>
                                    <td>${item['nome']}</td>
                                    <td>${item['parentesco'] == null ? 'Não informado' : item['parentesco']}</td>
                                    <td>${item['principal']}</td>
                                </tr>`
        })

        return `
            <h3 style="margin-bottom: 20px;">${grpType == 'P' ? 'Proprietários' : 'Residentes'}</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-3">#</th>
                                <th scope="col-3">Nome</th>
                                <th scope="col-3">Parentesco</th>
                                <th scope="col-3">Principal</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    private getTableAddress(list: Array<any>): string {
        if (list.length == 0) {
            return ''
        }

        var itensProperty = ``
        list.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td>${item['logradouro']}</td>
                                    <td>${item['numero'] == null ? '' : item['numero']}</td>
                                    <td>${item['cep'] == null ? '' : item['cep']}</td>
                                    <td>${item['bairro'] == null ? '' : item['bairro']}</td>
                                    <td>${item['distrito'] == null ? '' : item['distrito']}</td>
                                </tr>`
        })

        return `
            <h3 style="margin-bottom: 20px;">Endereço</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Logradouro</th>
                                <th scope="col">Número</th>
                                <th scope="col">CEP</th>
                                <th scope="col">Bairro</th>
                                <th scope="col">Distrito</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    private getVisitShapePDF(title: string, type: string, visit: Array<any>): string {
        let listFilter = visit.filter((x) => x['tabela'] == type)
        if (listFilter.length == 0) {
            return ''
        }

        var itensProperty = ``
        listFilter.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td scope="row">${index + 1}</td>
                                    <td>${item['data']}</td>
                                    <td>${item['profissional']}</td>
                                </tr>`
        })

        return `
            <h3 style="margin-bottom: 20px;">${title}</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-3">#</th>
                                <th scope="col-3">Data</th>
                                <th scope="col-3">Profissional</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    private openStreetView() {
        try {
            const coordinates = this.layerClick.feature.geometry.coordinates
            const type = this.layerClick.feature.geometry.type
            const cords = this.mapComponent?.getGeometryCenter(coordinates, type)

            if (cords) {
                const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${cords.lat},${cords.lng}`;

                window.open(streetViewUrl, '_blank');
            }
        } catch (error) {
            console.error("Erro ao abrir o Street View:", error);
        }
    }

    private duplicateShape() {
        confirmAlert({
            title: 'Duplicar Feição!',
            message: 'Deseja duplicar a feição selecionada?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    if (this.layerClick) {
                        this.props.viewModel.fetchDuplicateFeature(this.layerClick.feature).then((response) => {
                            const duplicatedFeature = { ...this.layerClick };
                            duplicatedFeature.feature.properties.sshape = response.Resultado[0];
                            // duplicatedFeature.feature.geometry.coordinates = adjustCoordinates(duplicatedFeature.feature.geometry.coordinates);

                            L.geoJSON(duplicatedFeature.feature, {
                                style: duplicatedFeature.feature.style,
                                onEachFeature: (feature, layer) => {
                                    if (feature.properties && this.mapComponent?.shapesAtivos) {
                                        this.mapComponent.shapesAtivos = layer;
                                    }
                                    layer.on({
                                        click: this.clickShape.bind(this)
                                    });
                                }
                            }).addTo(this.map);
                        });
                    }
                }
            },
            { label: 'Cancelar', onClick: () => { } }]
        });

        // function adjustCoordinates(coordinates: any) {
        // const offset = 0.000001
        //     if (Array.isArray(coordinates[0])) {
        //         return coordinates.map((coord: any) => adjustCoordinates(coord));
        //     }
        //     return [coordinates[0] +offset, coordinates[1] + offset];
        // }
    }

    private rotateShape() {
        this.setState({
            activedRotateMode: true
        })
        if (this.layerClick.pm) {
            this.layerClick.pm.enableRotate();
            this.layerClick.on('pm:rotate', (e: any) => {
                this.layerClick.feature.geometry.coordinates = e.newLatLngs

            });
        }
    }

    private saveRotatedShape() {
        const geoJson = this.layerClick.feature;
        const geoType = geoJson.geometry.type;
        let coords = geoJson.geometry.coordinates;

        if (geoType === 'MultiPolygon') {
            coords = coords[0][0];
        } else if (geoType === 'Polygon' || geoType === 'MultiLineString') {
            coords = coords[0];
        } else if (geoType === 'LineString' || geoType === 'Point') {
            coords = coords;
        }
        const newCords = coords.map((latLng: any) => [latLng.lng, latLng.lat]);
        newCords.push(newCords[0])

        if (geoType === 'MultiPolygon') {
            coords = [[newCords]];
        } else if (geoType === 'Polygon' || geoType === 'MultiLineString') {
            coords = [newCords];
        } else if (geoType === 'LineString' || geoType === 'Point') {
            coords = newCords;
        }

        this.layerClick.feature.geometry.coordinates = coords
        this.props.viewModel.fetchRotateFeature(this.layerClick.feature).then((response) => {
            toast.success("Alterado com sucesso")
        })

        this.layerClick.pm.disableRotate();
        this.setState({
            activedRotateMode: false
        })
    }

    public separateShape() {
        confirmAlert({
            title: 'Separação de Feição',
            message: 'Desenhe como deseja fazer a separação',
            buttons: [
                {
                    label: 'Ok', onClick: () => { this.startDrawingPolygon() }
                },
                {
                    label: 'Cancelar', onClick: () => {
                        // this.props.mapComponent.props.getMap()
                        this.mapComponent?.setState({
                            activeSeparatedFeature: false
                        })
                    }
                }
            ]
        })
    }

    private startDrawingPolygon() {
        this.setState({
            isSeparating: true
        })
        this.map.doubleClickZoom.disable();
        this.mapComponent?.setState({ activeSeparatedFeature: true })

        this.map.pm.enableDraw('Polygon', {
            tooltips: false,
            allowSelfIntersection: false
        });
    }

    private unificateShape() {
        if (this.state.activedShapeUnification) {
            if (!this.state.selectedShapes || this.state.selectedShapes?.length < 2) {
                confirmAlert({
                    title: 'Atenção!',
                    message: 'Selecione ao menos 2 Feições para unificá-las.',
                    buttons: [
                        { label: 'Ok', onClick: () => { } },
                        {
                            label: 'Cancelar', onClick: () => {
                                this.setState({
                                    activedShapeUnification: false
                                })
                            }
                        }
                    ]

                })
            } else {
                confirmAlert({
                    title: 'Atenção!',
                    message: 'Deseja unificar as feições selecionados?',
                    buttons: [{
                        label: 'Sim',
                        onClick: () => {
                            if (this.state.selectedShapes) {
                                this.setState({
                                    activedShapeUnification: false
                                })
                                this.mapComponent?.setState({
                                    openUnifyFeaturesModal: true
                                })
                            }
                        }
                    },
                    {
                        label: 'Cancelar',
                        onClick: () => {
                            this.mapComponent?.restoreSelectedShapes()
                            this.setState({
                                activedShapeUnification: false,
                                selectedShapes: []
                            })
                        }
                    }]
                })
            }
        } else {
            confirmAlert({
                title: 'Unificar feições',
                message: 'Selecione as Feições que deseja unificar, e em seguida clique novamente no botão.',
                buttons: [{ label: 'Ok', onClick: () => { } }]
            })
            this.setState({
                selectedShapes: [],
                activedShapeUnification: true
            })
        }
    }

}
