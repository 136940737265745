import { area } from '@turf/turf';
import * as jsts from 'jsts';
import * as L from 'leaflet';
import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Modal, Row, Table } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { ModalUnifyFeaturesInteractor } from '../Coordinator/ModalUnifyFeatures/ModalUnifyFeatures';
import '../Estilo/FeatureUnificatorModal.css';
import BiggestAreaIcon from '../Imagens/biggest-area.svg';
import RemoveFeatureIcon from '../Imagens/remove-feature.svg';
import IgnoreFeaturesIcon from '../Imagens/arrow-right.svg';


interface ModalUnifyFeaturesComponentProps {
    selectedShapes: any[];
    show: boolean;
    handleClose: () => void;
    setSelectedShapes: (selectedShapes: Array<any>) => void;
    onLayerClick: (e: any) => void
    getMap: () => any
}

export default function ModalUnifyFeaturesComponent({ selectedShapes, show, handleClose, setSelectedShapes, onLayerClick, getMap }: ModalUnifyFeaturesComponentProps) {
    const map = getMap()
    const [shapes, setShapes] = useState<Array<any>>(selectedShapes)
    const [shapesWithAttributes, setShapesWithAttributes] = useState<Array<any>>()
    const [selectedFeature, setSelectedFeature] = useState<number | null>(null);
    const [isIgnoreField, setIsIgnoreField] = useState<boolean>(false);

    useEffect(() => {
        if (shapes) {
            const shapeArray = selectedShapes.map((shape) => shape.feature.properties.sshape)
            const modalUnifyFeaturesInteractor = new ModalUnifyFeaturesInteractor()
            modalUnifyFeaturesInteractor.fetchGetShapesAttributes(shapeArray).then((response) => {
                if (response) {
                    setShapesWithAttributes(response)
                }
            })
        }
    }, [shapes])

    function handleKeepLargestArea() {
        if (shapes && shapes.length > 1) {
            let largestShape = shapes[0];
            let maxArea = area(largestShape.feature);
            shapes.forEach((shape, key) => {
                const shapeArea = area(shape.feature);
                if (shapeArea > maxArea) {
                    largestShape = shape;
                    maxArea = shapeArea;

                }
            });
            setSelectedFeature(largestShape.feature.properties.sshape);
        }
    };
    function handleRemoveFeature() {
        if (shapes && shapes?.length > 2) {
            if (selectedFeature !== null && shapes) {
                const updatedShapes = shapes.filter(
                    (shape) => shape.feature.properties.sshape !== selectedFeature
                );

                setShapes(updatedShapes)
                setSelectedShapes(updatedShapes);
                setSelectedFeature(null);
            }
        } else {
            confirmAlert({
                title: 'Atenção!',
                message: 'Para a unificação deve conter ao menos 2 Feições selecionadas.',
                buttons: [{ label: 'Ok', onClick: () => { } }]
            })
        }
    };

    async function handleOk() {
        if (!selectedFeature && !isIgnoreField) {
            confirmAlert({
                title: 'Atenção!',
                message: 'Selecione a feição que deseja manter as propriedades.',
                buttons: [{ label: 'Ok', onClick: () => { } }]
            })
        }
        let firtsFeature = selectedFeature
        if (isIgnoreField) {
            firtsFeature = shapes[0].feature.properties.sshape
            setSelectedFeature(firtsFeature)
        }
        if (firtsFeature) {
            const sShapesArray = shapes.map((shape) => shape.feature.properties.sshape)
            const unionResult = unificateFeatures()

            const modalUnifyFeaturesInteractor = new ModalUnifyFeaturesInteractor()
            await modalUnifyFeaturesInteractor.fetchSave(sShapesArray, firtsFeature, unionResult, isIgnoreField).then((response) => {
                if (response.length) {
                    addToMap(unionResult)
                    toast.success("Unificação feita com sucesso")
                }
            })
            handleClose()
            window.location.reload()
        }
    }



    function unificateFeatures() {
        if (shapes) {
            const shapeArray = shapes.map((shape) => {
                return shape.feature
            })

            return shapeArray.reduce((acc: any, currentPolygon: any) => {
                try {
                    return unifyFeature(acc, currentPolygon);
                } catch (error) {
                    return acc;
                }
            });
        }
    }



    function unifyFeature(poly1: any, poly2: any) {
        const reader = new jsts.io.GeoJSONReader();
        const writer = new jsts.io.GeoJSONWriter();

        const geom1 = reader.read(JSON.stringify(poly1.geometry));
        const geom2 = reader.read(JSON.stringify(poly2.geometry));

        const bufferedGeom1 = geom1.buffer(0.000001);
        const bufferedGeom2 = geom2.buffer(0.000001);

        const union = bufferedGeom1.union(bufferedGeom2);

        return {
            type: 'Feature',
            geometry: writer.write(union),
            properties: poly1.properties,
            style: poly1.style
        };
    }

    function addToMap(unionResult: any) {
        if (!map) return;
        shapes.forEach((shape) => {
            map.removeLayer(shape);
        });
        L.geoJSON(unionResult, {
            style: unionResult.style,
            onEachFeature: (feature, layer) => {
                layer.on({
                    click: (e) => onLayerClick(e)
                })
            }
        }).addTo(map);
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            size='lg'
            contentClassName='modal-features-content'
            dialogClassName='modal-features-dialog'
        >
            <Modal.Header closeButton>
                <Modal.Title>Unificar Feições</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='tableFeatures'>
                        <Table bordered hover >
                            <thead>
                                <tr>
                                    {shapesWithAttributes?.length && shapesWithAttributes[0].map((attribute: any) =>
                                        <th>{attribute.key}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody >
                                {shapesWithAttributes?.map((shape, index) =>
                                    <tr key={index} onClick={() => setSelectedFeature(shapesWithAttributes[index][0].value)}
                                        className={`pointer ${selectedFeature === shapesWithAttributes[index][0].value && !isIgnoreField ? 'selected-shape' : ''}`}>
                                        {shape.map((attribute: any) =>
                                            <>
                                                <td>{attribute.value}</td>
                                            </>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <Button className='action-button' onClick={handleKeepLargestArea}>
                        <Image
                            className='cursor-pointer'
                            src={BiggestAreaIcon}
                            width={28} />
                        <span className='mx-1'>Manter atributos da feição de maior área</span>
                    </Button>
                    <Button className='action-button' onClick={() => setIsIgnoreField(true)}>
                        <Image
                            className='cursor-pointer'
                            src={IgnoreFeaturesIcon}
                            width={28} />
                        <span className='mx-1'>Ignorar todos os campos</span>
                    </Button>
                    <Button className='action-button' onClick={handleRemoveFeature} disabled={selectedFeature === null}>
                        <Image
                            className='cursor-pointer'
                            src={RemoveFeatureIcon}
                            width={28} />
                        <span className='mx-1'>Remover feição da seleção</span>
                    </Button>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button variant="primary" onClick={handleOk}>
                            Ok
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}


