import { Request } from '../../Servidor/Request';

export class AdminProcessRelatoryInteractor {
    public fetchAdminProcess(filters: Array<any>): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("relatorio/listagem-processo-administrativo", { ...filters }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchAdminProcessRelatory(filters: Array<any>): PromiseLike<any> {
        let name = 'Relatorio_processo_administrativo.pdf'
        return new Promise((res, rej) => {
            return new Request().requestPdf("relatorio/listagem-processo-administrativo-pdf", { ...filters }, name).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }
                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}