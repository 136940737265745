import { Request } from '../../Servidor/Request';

export class VisitRelatoryInteractor {
    public fetchVisits(filters: Array<any>): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("relatorio/listagem-visita", { ...filters }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchVisitsRelatory(filters: Array<any>): PromiseLike<any> {
        let name = 'Relatorio_visitas.pdf'
        return new Promise((res, rej) => {
            return new Request().requestPdf("relatorio/listagem-visita-pdf", { ...filters }, name).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }
                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}