import { Request } from '../../Servidor/Request';

export class ModalUnifyFeaturesInteractor {

    public fetchSave(shapeList: Array<number>, shapeToKeep: number, unifiedShape: any, isIgnoreField: boolean): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("feicoes/unificar", { shapeList, shapeToKeep, unifiedShape, isIgnoreField }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchGetShapesAttributes(shapeList: Array<number>): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost('formulario/ponto/buscar-atributo', { shapeList }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }
                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}