import React from 'react';
import ProtectedRoute from '../../Components/ProtectedRoute';
import { AdminProcessRelatory, AdminProcessRelatoryViewController } from "../../Screen/AdminProcessRelatory/AdminProcessRelatoryController";
import { AdminProcessRelatoryViewModel } from '../../Screen/AdminProcessRelatory/AdminProcessRelatoryViewModel';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";

export class AdminProcessRelatoryCoordinator implements IndexCoordinatorDelegate, AdminProcessRelatory {

    getRoute(): JSX.Element[] {
        return [
            this.routeAdminProcessRelatory(),
        ]
    }

    private routeAdminProcessRelatory(): JSX.Element {
        var viewModel = new AdminProcessRelatoryViewModel()
        return (<ProtectedRoute requiredPermissions={['relatorios.proc_administrativo.*']} path="/:base/relatorio/processo-administrativo" exact={true} component={(props: any) => {
            return (<AdminProcessRelatoryViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
            />)
        }} />)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
}