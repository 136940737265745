import React from 'react';
import { Button, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { NavBarComponente } from '../../Components/NavBarComponente';
import "../../Estilo/Form.css";
import baixo from '../../Imagens/arrow-down-blue-16.png';
import voltar from '../../Imagens/arrow-left-16.png';
import voltarInicio from '../../Imagens/arrow-left-first-16.png';
import proximo from '../../Imagens/arrow-right-16.png';
import proximoUltimo from '../../Imagens/arrow-right-last-16.png';
import cima from '../../Imagens/arrow-up-blue-16.png';
import clearFilter from '../../Imagens/clear-filter.png';
import fechar from '../../Imagens/close-24.png';
import filtrar from '../../Imagens/filter-16.png';
import printer from '../../Imagens/printer.png';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { TypePagination } from '../TableGeneric/TableGenericViewController';
import { ImobiliaryRegisterRelatoryViewModel } from './ImobiliaryRegisterRelatoryViewModel';
import { GlobalViewModel } from '../Global/GlobalViewModel';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';

interface MyProps {
    navigation: any
    delegate: ImobiliaryRegisterRelatory
    viewModel: ImobiliaryRegisterRelatoryViewModel
}

export interface ImobiliaryRegisterRelatory {
    tapDimiss(navigation: any): void
}
export interface Filters {
    inscricaomunicipal: string;
    numeromatricula: string;
    numerolote: string;
    numeroquadra: string;
    sgepdistrito: string;
    sgepzoneamento: string;
    logradouro: string;
    sgepbairros: string;
    numPropriedades: string;
    tpprop: string;
    sgeppessoa: string;
    cgepsituacoesshape: string;
    numerogleba: string;
    sgeplocalizacaorural: string;
    seccao: string;
    pagina: number;
    numHistorico: number;
    isTotalPoints: boolean
}

export class ImobiliaryRegisterRelatoryViewController extends React.Component<MyProps> {

    private globalViewModel = new GlobalViewModel()
    private formEvent: FormEvent
    state = {
        isLoading: true,
        json: {},
        data: Array<any>(),
        total: 0,
        neighborhoodList: Array<any>(),
        zoningList: Array<any>(),
        districtList: Array<any>(),
        ownerList: Array<any>(),
        situationList: Array<any>(),
        localizacaoList: Array<any>(),
        propTypeList: [
            { value: 'U', label: 'Urbano' },
            { value: 'R', label: 'Rural' },
        ],
        clearValue: false,
        showFilter: false,
        filters: ({
            inscricaomunicipal: '',
            numeromatricula: '',
            numerolote: '',
            numeroquadra: '',
            numHistorico: '',
            sgepdistrito: '',
            sgepzoneamento: '',
            logradouro: '',
            sgepbairros: '',
            sgeppessoa: '',
            numPropriedades: '',
            cgepsituacoesshape: '',
            tpprop: '',
            numerogleba: '',
            sgeplocalizacaorural: '',
            seccao: '',
            isTotalPoints: false,
            pagina: 1
        }),
        countPage: 1
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        })
    }

    render() {
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Relatório Cadastro Imobiliário</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                        onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                        src={fechar} />
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="2" xs="12" style={{ marginBottom: "20px" }}>
                                    <Form.Label>Tipo da Propriedade</Form.Label>
                                    <Select
                                        id="tpprop"
                                        value={this.state.filters.tpprop ? this.formEvent.onValueSelect('select', 'camada', this.state.propTypeList) : null}
                                        onChange={(e: any) => {
                                            this.loadData(e.value)
                                            this.setState({
                                                clearValue: true
                                            })
                                        }}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.propTypeList}
                                    />
                                </Col>

                                <Col lg="2" xs="12" >
                                    <Form.Label className='d-flex justify-content-center'>Total de Pontos no Sistema</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        className='d-flex justify-content-center'
                                        checked={this.state.filters.isTotalPoints}
                                        onChange={(e: any) => {
                                            this.verifyFilters('isTotalPoints', e.target.checked)
                                            if (this.state.filters.tpprop) {
                                                this.setState({
                                                    filters: {
                                                        ...this.state.filters,
                                                        tpprop: ''
                                                    }
                                                })
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            {(this.state.filters.tpprop || this.state.filters.isTotalPoints) &&
                                <>
                                    <Row className="header">
                                        {!this.state.filters.isTotalPoints &&
                                            <Col xs="12" className="infoFilter">
                                                <h3 onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                                                    <Image
                                                        className="iconFilter"
                                                        src={this.state.showFilter ? cima : baixo} />
                                                    EXIBIR FILTROS
                                                </h3>
                                            </Col>}

                                        {this.state.showFilter && !this.state.filters.isTotalPoints &&
                                            <Col xs="12" className="containerFilter">
                                                <Form>
                                                    <Row>
                                                        <Col lg="2" xs="12" className="bottom10">
                                                            <Form.Label>Inscrição Municipal</Form.Label>
                                                            <Form.Control
                                                                id="inscricaomunicipal"
                                                                type="text"
                                                                value={this.state.filters.inscricaomunicipal}
                                                                onChange={(e: any) => this.verifyFilters('inscricaomunicipal', e.target.value)}
                                                                placeholder="Digite..." />
                                                        </Col>
                                                        <Col lg="2" xs="12" className="bottom10">
                                                            <Form.Label>Nº Matrícula</Form.Label>
                                                            <Form.Control
                                                                id="numeromatricula"
                                                                type="text"
                                                                value={this.state.filters.numeromatricula}
                                                                onChange={(e: any) => this.verifyFilters('numeromatricula', e.target.value)}
                                                                placeholder="Digite..." />
                                                        </Col>
                                                        {this.state.filters.tpprop == "U" ?
                                                            <>
                                                                <Col lg="2" xs="12" className="bottom10">
                                                                    <Form.Label>Nº Lote</Form.Label>
                                                                    <Form.Control
                                                                        id="numerolote"
                                                                        type="text"
                                                                        value={this.state.filters.numerolote}
                                                                        onChange={(e: any) => this.verifyFilters('numerolote', e.target.value)}
                                                                        placeholder="Digite..." />
                                                                </Col>
                                                                <Col lg="2" xs="12" className="bottom10">
                                                                    <Form.Label>Nº Quadra</Form.Label>
                                                                    <Form.Control
                                                                        id="numeroquadra"
                                                                        type="text"
                                                                        value={this.state.filters.numeroquadra}
                                                                        onChange={(e: any) => this.verifyFilters('numeroquadra', e.target.value)}
                                                                        placeholder="Digite..." />
                                                                </Col>
                                                                <Col lg="2" xs="12" className="bottom10">
                                                                    <Form.Label>Bairro</Form.Label>
                                                                    <Select
                                                                        id="sgepbairros"
                                                                        value={this.state.filters.sgepbairros ? this.formEvent.onValueSelect('select', 'camada', this.state.neighborhoodList) : null}
                                                                        onChange={(e: any) => this.verifyFilters('sgepbairros', e ? e.value : null)}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.neighborhoodList}
                                                                        isClearable
                                                                    />
                                                                </Col>
                                                            </>
                                                            :
                                                            <>
                                                                <Col lg="1" xs="12" className="bottom10">
                                                                    <Form.Label>Gleba</Form.Label>
                                                                    <Form.Control
                                                                        id="numerogleba"
                                                                        type="text"
                                                                        value={this.state.filters.numerogleba}
                                                                        onChange={(e: any) => this.verifyFilters('numerogleba', e.target.value)}
                                                                        placeholder="Digite..." />
                                                                </Col>
                                                                <Col lg="3" xs="12" className="bottom10">
                                                                    <Form.Label>Localização</Form.Label>
                                                                    <Select
                                                                        id="localizacao"
                                                                        value={this.state.filters.sgeplocalizacaorural ? this.formEvent.onValueSelect('select', 'camada', this.state.localizacaoList) : null}
                                                                        onChange={(e: any) => this.verifyFilters('sgeplocalizacaorural', e ? e.value : null)}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.localizacaoList}
                                                                        isClearable
                                                                    />
                                                                </Col>
                                                                <Col lg="2" xs="12" className="bottom10">
                                                                    <Form.Label>Secção</Form.Label>
                                                                    <Form.Control
                                                                        id="seccao"
                                                                        type="text"
                                                                        value={this.state.filters.seccao}
                                                                        onChange={(e: any) => this.verifyFilters('seccao', e.target.value)}
                                                                        placeholder="Digite..." />
                                                                </Col>
                                                            </>

                                                        }
                                                        <Col lg="2" xs="12" className="bottom10">
                                                            <Form.Label>Distrito</Form.Label>
                                                            <Select
                                                                id="sgepdistrito"
                                                                value={this.state.filters.sgepdistrito ? this.formEvent.onValueSelect('select', 'camada', this.state.districtList) : null}
                                                                onChange={(e: any) => this.verifyFilters('sgepdistrito', e ? e.value : null)}
                                                                closeMenuOnSelect={true}
                                                                placeholder={"Selecione"}
                                                                options={this.state.districtList}
                                                                isClearable
                                                            />
                                                        </Col>
                                                        <Col lg="2" xs="12" className="bottom10">
                                                            <Form.Label>Zoneamento</Form.Label>
                                                            <Select
                                                                id="sgepzoneamento"
                                                                value={this.state.filters.sgepzoneamento ? this.formEvent.onValueSelect('select', 'camada', this.state.zoningList) : null}
                                                                onChange={(e: any) => this.verifyFilters('sgepzoneamento', e ? e.value : null)}
                                                                closeMenuOnSelect={true}
                                                                placeholder={"Selecione"}
                                                                options={this.state.zoningList}
                                                                isClearable
                                                            />
                                                        </Col>

                                                        <Col lg="2" xs="12" className="bottom10">
                                                            <Form.Label>Situação</Form.Label>
                                                            <Select
                                                                id="cgepsituacoesshape"
                                                                value={this.state.filters.cgepsituacoesshape ? this.formEvent.onValueSelect('select', 'camada', this.state.situationList) : null}
                                                                onChange={(e: any) => this.verifyFilters('cgepsituacoesshape', e ? e.value : null)}
                                                                closeMenuOnSelect={true}
                                                                placeholder={"Selecione"}
                                                                options={this.state.situationList}
                                                                isClearable
                                                            />
                                                        </Col>

                                                        <Col lg="2" xs="12" className="bottom10">
                                                            <InputModalSelectComponent
                                                                id="sgeppessoa"
                                                                keyValue="nome"
                                                                label="Proprietario"
                                                                required={false}
                                                                placeholder="Procure"
                                                                showAddButton={false}
                                                                navigation={this.props.navigation}
                                                                titleModal="Pessoas"
                                                                table="geppessoas"
                                                                valueDefault={this.state.filters.sgeppessoa}
                                                                getValue={(id: number, value: string) => this.verifyFilters('sgeppessoa', String(id))}
                                                                clearValue={this.state.clearValue}
                                                                setClearValue={() => this.setState({ clearValue: false })}
                                                            />
                                                        </Col>


                                                        {this.state.filters.tpprop == "U" &&
                                                            <Col lg="2" xs="12" className="bottom10">
                                                                <Form.Label>Logradouro</Form.Label>
                                                                <Form.Control
                                                                    id="logradouro"
                                                                    type="text"
                                                                    value={this.state.filters.logradouro}
                                                                    onChange={(e: any) => this.verifyFilters('logradouro', e.target.value)}
                                                                    placeholder="Digite..." />
                                                            </Col>}

                                                        <Col lg="2" xs="12" className="bottom10">
                                                            <Form.Label>Quantidade Propriedades</Form.Label>
                                                            <Form.Control
                                                                id="numPropriedades"
                                                                type="number"
                                                                value={this.state.filters.numPropriedades}
                                                                onChange={(e: any) => this.verifyFilters('numPropriedades', e.target.value)}
                                                                placeholder="Digite..." />
                                                        </Col>
                                                        <Col lg="2" xs="12" >
                                                            <Form.Label>Número de Histórico</Form.Label>
                                                            <Form.Control
                                                                id="numHistorico"
                                                                type="number"
                                                                value={this.state.filters.numHistorico}
                                                                onChange={(e: any) => this.verifyFilters('numHistorico', e.target.value)}
                                                                min={0}
                                                                placeholder="Digite..." />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs='12'>
                                                            <Button
                                                                onClick={() => this.screenUpdate()}
                                                                variant="info"
                                                                type="button"
                                                                className="btnFilter right10">
                                                                <Image
                                                                    className="iconBtnFilter"
                                                                    src={filtrar} />
                                                                Filtrar
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    this.clearFilters()
                                                                    this.setState({
                                                                        clearValue: true
                                                                    })
                                                                }}
                                                                variant="info"
                                                                type="button"
                                                                className="btnFilter">
                                                                <Image
                                                                    className="iconBtnFilter"
                                                                    src={clearFilter} />
                                                                Limpar
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>}

                                        <Col xs="12" style={{ marginTop: 20 }}>
                                            <Button variant="danger" type="button" className="buttonGeneric colorRed"
                                                onClick={() => this.printRelatory(this.state.filters, this.state.filters.tpprop)}>
                                                <Image
                                                    className="iconButtonGeneric"
                                                    src={printer} />
                                                PDF
                                            </Button>
                                        </Col>

                                    </Row>

                                </>
                            }

                            {(!!this.state.filters.tpprop || !!this.state.filters.isTotalPoints) &&
                                <>
                                    <Table responsive hover style={{ textAlign: 'left', fontSize: 14, backgroundColor: "#ffffff" }}>
                                        {this.state.filters.isTotalPoints ?
                                            <>
                                                <thead style={{ backgroundColor: "#eeeeee" }}>
                                                    <tr>
                                                        <th>Número de Pontos</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {this.state.data && this.state.data.map((item, key) => (
                                                        <tr key={key}>
                                                            <td>{item.pointnumber}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </>
                                            : this.state.filters.tpprop == "U" ? (
                                                <>
                                                    <thead style={{ backgroundColor: "#eeeeee" }}>
                                                        <tr>
                                                            <th>Inscrição imobiliária</th>
                                                            <th>Matrícula</th>
                                                            <th>Quadra</th>
                                                            <th>Lote</th>
                                                            <th>Bairro</th>
                                                            <th>Proprietário</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {this.state.data && this.state.data.map((item, key) => (
                                                            <tr key={key} onClick={() => {/*navegate (item.id) */ }}>
                                                                <td>{item.inscricaomunicipal}</td>
                                                                <td>{item.numeromatricula}</td>
                                                                <td>{item.numeroquadra}</td>
                                                                <td>{item.numerolote}</td>
                                                                <td>{item.bairro}</td>
                                                                <td>{item.nome}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </>)
                                                :
                                                <>
                                                    <thead style={{ backgroundColor: "#eeeeee" }}>
                                                        <tr>
                                                            <th>Inscrição imobiliária</th>
                                                            <th>Matrícula</th>
                                                            <th>Gleba</th>
                                                            <th>Localidade</th>
                                                            <th>Distrito</th>
                                                            <th>Proprietário</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data && this.state.data.map((item, key) => (
                                                            <tr key={key} onClick={() => { /*navegate (item.id) */ }}>
                                                                <td>{item.inscricaomunicipal}</td>
                                                                <td>{item.numeromatricula}</td>
                                                                <td>{item.numerogleba}</td>
                                                                <td>{item.localizacao}</td>
                                                                <td>{item.distrito}</td>
                                                                <td>{item.nome}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </>
                                        }
                                    </Table>
                                    <div style={{ borderWidth: 1, borderStyle: "solid", borderColor: "#cccccc", backgroundColor: "#eeeeee" }}>
                                        <Container fluid={true}>
                                            <Row>
                                                <Col xs="12">
                                                    <div style={{ height: 30 }}>
                                                        <div style={{ float: "right" }}>
                                                            <label style={{ marginLeft: 10, fontSize: 12, fontWeight: "bold" }}>Número de Registros: {this.state.total}</label>
                                                        </div>
                                                        {this.state.data.length ? (
                                                            <div style={{ float: "left" }}>
                                                                {this.state.filters.pagina != 1 && (
                                                                    <>
                                                                        <Image
                                                                            style={{ marginLeft: 0, cursor: "pointer" }}
                                                                            src={voltarInicio}
                                                                            onClick={() => this.pagination('first')}
                                                                            width={16}
                                                                            className="center"
                                                                            fluid />
                                                                        <Image
                                                                            style={{ marginLeft: 10, cursor: "pointer" }}
                                                                            src={voltar}
                                                                            onClick={() => this.pagination('back')}
                                                                            width={16}
                                                                            className="center"
                                                                            fluid />
                                                                    </>
                                                                )}
                                                                <label className="tablePage">{this.state.filters.pagina}/{this.state.countPage}</label>
                                                                {this.state.filters.pagina !== this.state.countPage && (
                                                                    <>
                                                                        <Image
                                                                            style={{ marginLeft: 10, cursor: "pointer" }}
                                                                            src={proximo}
                                                                            onClick={() => this.pagination('next')}
                                                                            width={16}
                                                                            className="center"
                                                                            fluid />
                                                                        <Image
                                                                            style={{ marginLeft: 10, cursor: "pointer" }}
                                                                            src={proximoUltimo}
                                                                            onClick={() => this.pagination('last')}
                                                                            width={16}
                                                                            className="center"
                                                                            fluid />
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : <label className="tablePage">0/{this.state.countPage}</label>}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </>
                            }

                        </Form>
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({ isLoading: this.state.isLoading })} />
            </div>
        )
    }

    private printRelatory(filters: any, tpprop: any) {
        this.setState({
            isLoading: true
        })
        if (this.state.filters.isTotalPoints) {
            this.props.viewModel.fetchPointNumberRelatory().then((data) => {
                this.setState({
                    isLoading: false
                })
            })
        } else {
            this.props.viewModel.fetchImobiliaryRegistersRelatory(filters, tpprop).then((data) => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    private loadData(tpprop: string) {
        this.verifyFilters('tpprop', tpprop);
        if (this.state.filters.tpprop) {
            if (this.state.filters.tpprop === tpprop) {
                this.setState({ isLoading: true }, () => {
                    this.screenUpdate();
                });
            }
            this.clearFilters()
            this.lists()
        }
    }

    private screenUpdate() {
        this.setState({ isLoading: true })
        this.props.viewModel.fetchImobiliaryRegisters(this.state.filters).then((response: any) => {
            if (response.length) {
                this.setState({
                    isLoading: false,
                    data: response,
                    countPage: Math.ceil(response[0].total / 10),
                    total: Number(response[0].total)
                })
            } else {
                this.setState({
                    isLoading: false,
                    data: response,
                    countPage: 0,
                    total: 0
                })
            }
        })
    }

    private lists() {

        this.globalViewModel.fetchDataToSelectInput('gepbairros', 'sgepbairros').then((response: any) => {
            if (response.length) {
                this.setState({
                    neighborhoodList: response
                })
            }
        })

        this.globalViewModel.fetchDataToSelectInput('gepdistrito', 'sgepdistrito').then((response: any) => {
            if (response.length) {
                this.setState({
                    districtList: response
                })
            }
        })
        this.globalViewModel.fetchDataToSelectInput('gepzoneamento', 'sgepzoneamento').then((response: any) => {
            if (response.length) {
                this.setState({
                    zoningList: response
                })
            }
        })

        this.globalViewModel.fetchDataToSelectInput('geppessoas', 'sgeppessoa').then((response: any) => {
            if (response.length) {
                this.setState({
                    ownerList: response
                })
            }
        })

        this.globalViewModel.fetchDataToSelectInput('gepsituacoesshape', 'cgepsituacoesshape').then((response: any) => {
            if (response.length) {
                this.setState({
                    situationList: response
                })
            }
        })

        this.state.filters.tpprop === "R" &&
            this.globalViewModel.fetchDataToSelectInput('geplocalizacaorural', 'sgeplocalizacaorural').then((response: any) => {
                if (response.length) {
                    this.setState({
                        localizacaoList: response
                    })
                }
            })

    }

    private verifyFilters(key: keyof Filters, newValue: string) {
        let newFilters = this.state.filters as any;
        newFilters[key] = newValue;
        if (key !== 'pagina') newFilters['pagina'] = 1;
        this.setState({
            filters: newFilters
        });
        if (key == "isTotalPoints") {
            this.screenUpdate()
        }
    }

    private async pagination(type: TypePagination) {
        var page
        switch (type) {
            case 'first':
                page = 1
                break;
            case 'back':
                page = this.state.filters.pagina - 1
                break;
            case 'next':
                page = this.state.filters.pagina + 1
                break;
            case 'last':
                page = this.state.countPage
                break;
        }
        await this.setState({
            filters: {
                ...this.state.filters,
                pagina: page
            }
        })
        this.screenUpdate()
    }

    private async clearFilters() {
        await this.setState({
            filters: {
                inscricaomunicipal: '',
                numeromatricula: '',
                numerolote: '',
                numeroquadra: '',
                numHistorico: '',
                sgepdistrito: '',
                sgepzoneamento: '',
                logradouro: '',
                sgepbairros: '',
                sgeppessoa: '',
                numPropriedades: '',
                numerogleba: '',
                sgeplocalizacaorural: '',
                seccao: '',
                isTotalPoints: false,
                tpprop: this.state.filters.tpprop,
                pagina: 1
            }
        })
        this.screenUpdate()
    }
}